export const languageSelect = [
  { name: 'language.list.zh', code: 'zh-cn' },
  { name: 'language.list.en', code: 'en' },
  { name: 'language.list.es', code: 'es' },
  { name: 'language.list.de', code: 'de' },
  { name: 'language.list.fr', code: 'fr' },
  { name: 'language.list.pt_BR', code: 'pt-br' }
]

export function getLocaleFromNavigator () {
  let lang = 'en'
  if (!window) return lang
  const userLanguage = (window.navigator.language || window.navigator.languages[0] || window.navigator.browserLanguage).toLowerCase()
  /**
   * switch匹配多项，是在苹果系统，返回的lang code与浏览器端返回的不一致
   */
  switch (userLanguage) {
    case 'zh-cn': lang = 'zh-cn'
      break
    case 'en': lang = 'en'
      break
    case 'en-us': lang = 'en'
      break
    case 'es': lang = 'es'
      break
    case 'es-es': lang = 'es'
      break
    case 'de': lang = 'de'
      break
    case 'de-de': lang = 'de'
      break
    case 'fr': lang = 'fr'
      break
    case 'fr-fr': lang = 'fr'
      break
    case 'pt-br': lang = 'pt-br'
      break
    default: lang = 'en'
  }
  return lang
}

export function getDevice () {
  if (window.innerWidth < 768) {
    return 'small'
  } else if (window.innerWidth >= 768 && window.innerWidth < 1060) {
    return 'medium'
  } else if (window.innerWidth >= 1060) {
    return 'large'
  }
  return 'large'
}
