import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueLazyload from 'vue-lazyload'
// import VConsole from 'vconsole'
import 'windi.css'
import './styles/global.css'
import './assets/iconfont/iconfont'
import SvgIcon from './components/svg-icon.vue'
import Footer from './components/footer.vue'
Vue.config.productionTip = false

// const vConsole = new VConsole()
// console.log(vConsole)

Vue.use(VueLazyload)
Vue.component('inst-icon', SvgIcon)
Vue.component('inst-footer', Footer)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
