<template>
  <section class="shadow w-full flex flex-col text-white bg-inst262 lg:(w-full flex flex-col text-white bg-inst262)">
    <!--  -->
    <div class="px-inst22 py-20px flex flex-col items-center lg:(px-160px py-40px)">
      <div>
        <img src="@/assets/images/footer/logo.png" alt="instpower" class="w-160px lg:(w-120px h-25px)">
      </div>
      <div class="mt-12px flex lg:(mt-20px flex)">
        <div class="flex items-center">
          <span class="inline-block lg:(inline-block)">
            <inst-icon icon-class="email" style="width:16px;height:16px;" />
          </span>
          <a href="mailto:hi@instpower.com" class="ml-6px text-16px text-inst999 leading-inst lg:(ml-7px text-16px text-inst999 leading-inst)">hi@instpower.com</a>
        </div>
      </div>
    </div>
    <div class="top-border px-inst22 py-24px flex flex-col items-center lg:(px-160px py-40px)">
      <div class="mb-10px leading-22px text-16px text-inst999 lg:(mb-10px leading-22px text-16px text-inst999)">
        {{ $t('footer.partner') }}
      </div>
      <div class="flex flex-col lg:(flex flex-row items-center)">
        <a class="links" href="https://www.anker.com" target="_blank">
          <img src="@/assets/images/footer/partner_anker.png" alt="" class="h-28px lg:(w-126px h-32px)">
        </a>
        <a href="https://www.sunwoda.com" target="_blank" class="links my-10px lg:(my-0 mx-73px)">
          <img src="@/assets/images/footer/partner_sunwoda.png" alt="" class="h-28px lg:(w-126px h-32px)">
        </a>
        <a class="links" href="https://alchemypay.org" target="_blank">
          <img src="@/assets/images/footer/partner_alchemy.png" alt="" class="h-28px lg:(w-126px h-32px)">
        </a>
      </div>
    </div>
    <!--  -->
    <div class="footer-border px-inst22 py-24px flex flex-row flex-wrap justify-between items-center lg:(px-160px py-12px flex flex-row justify-between items-center)">
      <div class="flex-1 mr-50px">
        <select @change="langChange" name="select" class="footer-select w-full max-w-330px lg:(w-330px)">
          <option
            v-for="item in languageList"
            :key="item.code"
            :value="item.code"
            :selected="langCode === item.code"
          >
            {{ $t(item.name) }}
          </option>
        </select>
      </div>
      <div class="flex items-center lg:(mt-0 flex items-center)">
        <a href="https://twitter.com/instpower" target="_blank" class="inline-block lg:(inline-block)">
          <inst-icon icon-class="twitter" style="width:28px;height:28px;" />
        </a>
        <a href="https://www.facebook.com/Instpower/" target="_blank" class="inline-block mx-24px lg:(inline-block mx-44px)">
          <inst-icon icon-class="facebook-fill" style="width:28px;height:28px;" />
        </a>
        <a href="https://www.linkedin.com/company/instpower" target="_blank" class="inline-block lg:(inline-block)">
          <inst-icon icon-class="linkedin-fill" style="width:28px;height:28px;" />
        </a>
      </div>
    </div>
    <!--  -->
    <div class="px-inst22 pt-20px pb-70px text-inst999 flex flex-col items-center md:(py-20px) lg:(flex-row justify-center px-160px)">
      <span class="text-14px mb-10px lg:(mb-0 mr-60px)">{{ `©${currentYear} INSTPOWER CO., LTD.` }}</span>
      <span class="span-button text-16px" @click="jump">
        {{ $t('footer.privacy') }}
      </span>
    </div>
  </section>
</template>

<script>
import { languageSelect } from '@/utils'
export default {
  data () {
    return {
      languageList: languageSelect,
      langCode: ''
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        this.langCode = val
      },
      immediate: true
    }
  },
  methods: {
    langChange (e) {
      const langCode = e.target.value
      this.$i18n.locale = langCode
      window.localStorage.setItem('current-lang-code', langCode)
      this.$router.replace({
        name: this.$route.name,
        params: {
          lang: langCode
        }
      })
    },
    jump () {
      this.$router.push({
        name: 'privacy'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1)
}
.top-border {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-border {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-select {
  height: 44px;
  padding: 5px 0px;
  outline: none;
  cursor: pointer;
  color: white;
  opacity: .5;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  background-color: #262424;
}

.links {
  &:active {
    opacity: 0.6;
  }
}
.span-button {
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #02d250;
  }

  &:active {
    opacity: 0.6;
  }
}
</style>
