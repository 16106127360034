import Vue from 'vue'
import VueI18n from 'vue-i18n'
// eslint-disable-next-line camelcase
import zh_CN from './languages/zh-CN.json'
import en from './languages/en.json'
import es from './languages/es.json'
import de from './languages/de.json'
import fr from './languages/fr.json'
// eslint-disable-next-line camelcase
import pt_BR from './languages/pt-BR.json'

Vue.use(VueI18n)

const messages = {
  'zh-cn': zh_CN,
  en: en,
  es,
  de,
  fr,
  'pt-br': pt_BR
}

export const langKeys = Object.keys(messages)

export default new VueI18n({
  locale: 'en',
  messages
})
