const routes = [
  {
    path: '/:lang/customer',
    name: 'customer',
    component: () => import('@/pages/customer/index')
  },
  {
    path: '/:lang/faq',
    name: 'faq',
    component: () => import('@/pages/faq/index')
  },
  {
    path: '/:lang/partner',
    name: 'partner',
    component: () => import('@/pages/partner/index')
  },
  {
    path: '/:lang/product',
    name: 'product',
    component: () => import('@/pages/product-center/index')
  },
  {
    path: '/:lang/privacy',
    name: 'privacy',
    component: () => import('@/pages/privacy/index')
  },
  {
    path: '*',
    redirect: {
      name: 'customer'
    }
  }
]

export default routes
