import Vue from 'vue'
import Router from 'vue-router'
import routes from './route-map'
import { langKeys } from '@/i18n/'

Vue.use(Router)

const router = new Router({
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || (to.params.pathMatch && to.params.pathMatch.replace('/', ''))

  if (langKeys.includes(lang)) {
    window.localStorage.setItem('current-lang-code', lang)
    to.params.lang = lang
    next()
  } else {
    const currentLang = window.localStorage.getItem('current-lang-code') || 'en'
    next({
      name: to.name,
      params: {
        lang: currentLang
      }
    })
  }
})

export default router
