<template>
  <div class="nav font-600">

    <!-- lg -->
    <div class="hidden select-none lg:(block fixed top-0 left-0 z-9999 h-60px w-full bg-white px-75px flex justify-between items-center border-b-1px border-inste1e)">
      <!-- logo -->
      <h1 class="cursor-pointer lg:(w-132px h-full flex justify-center items-center)" @click="toIndex">
        <inst-icon icon-class="logo" style="width:100%;height:28px" />
      </h1>

      <div class="menu lg:(flex justify-start items-center h-60px text-14px text-inst888)">
        <!-- menu -->
        <div
          v-for="item in menu"
          :key="item.name"
          class="menu cursor-pointer lg:(relative h-60px leading-60px px-10px mr-10px hover:text-instGreen)"
          :class="[item.className]"
          :ref="item.className"
        >
          <a
            @click.prevent="(e) => handlerClick(item.routerName, e)"
            class="lg:(inline-block h-60px)"
          >
            <span>{{ $t(item.name) }}</span>
          </a>
          <!-- line -->
          <div class="lg:(absolute w-0px h-2px left-0px right-0px bottom-0 rounded-2px mx-auto bg-instGreen)"></div>
        </div>
        <!-- function button -->
        <div v-if="buttonLink.name" class="ml-10px">
          <a
            v-if="buttonLink.isLink"
            class="cursor-pointer inline-block hover:opacity-80 lg:(px-10px h-35px bg-instGreen text-14px font-bold leading-35px text-white rounded-3px)"
            :href="buttonLink.isLink"
            target="_blank"
          >
            {{ $t(buttonLink.name) }}
          </a>
          <div
            v-else
            class="cursor-pointer select-none inline-block hover:opacity-80 lg:(px-10px h-35px bg-instGreen text-14px font-bold leading-35px text-white rounded-3px)"
            @click="goTo"
          >
            {{ $t(buttonLink.name) }}
          </div>
        </div>
      </div>
    </div>

    <!-- sm -->
    <div
      class="truncate block fixed top-0 left-0 z-9999 w-full bg-white lg:(hidden)"
      :class="[expand ? 'h-full' : '']"
    >
      <div class="flex justify-between items-center h-60px px-22px border-b-1px border-inste1e">
        <h1 class="h-full flex justify-center items-center" @click="toIndex">
          <inst-icon icon-class="logo" style="width:128px;height:28px" />
        </h1>
        <div class="h-24px w-24px" @click="expandNav">
          <div
            class="nav_trigger"
            :class="[expand ? 'active' : '']"
          >
            <span class="nav_bar"></span>
            <span class="nav_bar"></span>
            <span class="nav_bar"></span>
          </div>
        </div>
      </div>
      <!-- menu -->
      <div
        v-if="expand"
        @click="expandNav"
        class="truncate flex flex-col justify-start items-center"
      >
        <div
          v-for="(item, index) in menu"
          :key="item.name"
          class="w-full cursor-pointer leading-26px text-18px text-center text-inst111 font-bold"
          :class="[
            item.className,
            index === 0 ? 'mt-15px' : '',
          ]"
        >
          <a
            @click.prevent="(e) => handlerClick(item.routerName, e)"
            class="inline-block w-full py-15px "
          >
            {{ $t(item.name) }}
          </a>
        </div>

        <!-- function button -->
        <div v-if="buttonLink.name" class="my-40px w-full px-22px">
          <a
            v-if="buttonLink.isLink"
            class="inline-block w-full py-10px text-18px text-white font-bold leading-26px text-center bg-instGreen rounded-4px"
            :href="buttonLink.isLink"
            target="_blank"
          >
            {{ $t(buttonLink.name) }}
          </a>
          <div
            v-else
            class="cursor-pointer select-none inline-block w-full py-10px text-18px text-white font-bold leading-26px text-center bg-instGreen rounded-4px"
            @click="goTo"
          >
            {{ $t(buttonLink.name) }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      required: true
    },
    buttonLink: {
      type: Object
    }
  },
  data () {
    return {
      expand: false
    }
  },
  mounted () {
    Object.keys(this.$refs).forEach(key => {
      const item = this.$refs[key][0]

      item.addEventListener('mouseenter', () => {
        item.children[1].classList.remove('removeLine')
        item.children[1].classList.add('line')
      })
    })

    Object.keys(this.$refs).forEach(key => {
      const item = this.$refs[key][0]

      item.addEventListener('mouseleave', () => {
        item.children[1].classList.remove('line')
        item.children[1].classList.add('removeLine')
      })
    })
  },
  methods: {
    handlerClick (name) {
      if (this.$route.name === name) return
      this.$router.push({
        name
      }).catch(err => err)
    },
    goTo () {
      window.location.href = window.location.origin + '/business'
    },
    toIndex () {
      this.expand = false
      this.$router.push({
        name: 'customer'
      }).catch(err => err)
    },
    expandNav () {
      this.expand = !this.expand
    }
  }
}
</script>

<style lang="scss" scoped>

.line {
  width: 100%;
  transition: width .5s linear;
}

.removeLine {
  width: 0;
  transition: width .5s linear;
}

.nav {

  &_trigger {
    position: relative;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;

    &.active .nav_bar {
      &:nth-child(1) {
        transform: translate(0, 11px) rotate(-44deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translate(0, -11px) rotate(46deg);
      }
    }
  }

  &_bar {
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #88898A;
    transition: transform 0.5s;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 11px;
    }
    &:nth-child(3) {
      bottom: 0px;
    }
  }
}

</style>
