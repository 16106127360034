<template>
  <div>
    <header>
      <inst-nav :menu="navMenu" :buttonLink="buttonLink" />
    </header>
    <div class="pt-60px">
      <router-view />
    </div>
    <footer class="mt-60px lg:(mt-80px)">
      <inst-footer />
    </footer>
  </div>
</template>

<script>
import { getLocaleFromNavigator, getDevice } from '@/utils'
import Nav from '@/components/nav'

export default {
  components: {
    'inst-nav': Nav
  },
  data () {
    return {
      navMenu: [
        {
          name: 'nav.introduce',
          className: 'introduce',
          routerName: 'customer'
        },
        {
          name: 'nav.partner',
          className: 'partner',
          routerName: 'partner'
        },
        {
          name: 'nav.center',
          className: 'product',
          routerName: 'product'
        }
      ],
      buttonLink: {
        isLink: 'https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAADay_0dUOUwyMDBHV05GUVU5MFg1QkZZM0g0VFdNTC4u',
        name: 'nav.button'
      }
    }
  },
  created () {
    this.resize()
  },
  mounted () {
    window.addEventListener('resize', this.resize)
    const userLang = getLocaleFromNavigator()
    const langCode = window.localStorage.getItem('current-lang-code') || userLang
    this.$i18n.locale = langCode
    window.localStorage.setItem('current-lang-code', langCode)
  },
  updated () {
    const userLang = getLocaleFromNavigator()
    const langCode = window.localStorage.getItem('current-lang-code') || userLang
    this.$i18n.locale = langCode
    window.localStorage.setItem('current-lang-code', langCode)
  },
  methods: {
    resize () {
      window.localStorage.setItem('device', getDevice())
    }
  }
}
</script>
